<template>
  <div class="section">
    <!--탭메뉴 s-->
    <tab-menu/>
    <!--탭메뉴 e-->

    <ul class="tab_exp">
      <li>선생님의 관심 질환 영역 정보를 변경하실 수 있습니다.</li>
      <li>선생님께서 제공하신 관심 질환 영역 정보는 선생님께 최적화 된 맞춤 콘텐츠를 제공하기 위한 기초 데이터로 활용합니다.</li>
      <li>선생님께서는 현재 주요 관심 질환 영역으로 <span>'{{ taName }}'</span>를 선택하셨습니다.</li>
      <li>주요 관심 질환 영역을 변경하시고자 하는 경우, 아래 보기 중 선택 후 '저장하기' 버튼을 클릭해주세요.</li>
    </ul>

    <!--내용 영역 s-->
    <div class="maladie_chk">
      <p v-for="(ta,index) in therapeuticAreaList" :key="index">
        <input type="radio" name="maladie_radio" :value="ta.id" :id="`chk_maladie_${ta.id}`"
               v-model="selectedInterestTherapeuticArea">
        <label :for="`chk_maladie_${ta.id}`" v-text="ta.ename"></label>
      </p>
    </div>

    <div class="btn_area">
      <button class="btn_main" @click="saveTherapeuticArea">저장하기</button>
    </div>
    <!--내용 영역 e-->
  </div>
</template>

<script>
import TabMenu from "@/components/MyMenu/TabMenu";

export default {
  name: "InterestTherapeuticArea",
  components: {
    TabMenu
  },
  data() {
    return {
      interestTherapeuticArea: '',
      therapeuticAreaList: [],
      selectedInterestTherapeuticArea: ''
    }
  },
  created() {
    this.initMyInterestTherapeuticArea()
    this.initTherapeuticAreaList()
  },
  computed: {
    taName() {
      return this.therapeuticAreaList.find(value => value.id === this.interestTherapeuticArea)?.ename
    }
  },
  methods: {
    initMyInterestTherapeuticArea() {
      this.$store.dispatch('initMyInterestTherapeuticArea').then(interestTherapeuticArea => {
        this.interestTherapeuticArea = interestTherapeuticArea
        this.selectedInterestTherapeuticArea = interestTherapeuticArea
      })
    },
    initTherapeuticAreaList() { //
      this.$store.dispatch('initTherapeuticAreaList').then(therapeuticAreaList => {
        this.therapeuticAreaList = therapeuticAreaList
      })
    },
    saveTherapeuticArea() {
      if (this.selectedInterestTherapeuticArea) {
        this.$store.dispatch('dispatchMyInterestTherapeuticArea', {
          id: this.selectedInterestTherapeuticArea
        }).then(interestTherapeuticArea => {
          let query = {...this.$route.query}
          delete query.needta
          this.$router.replace({ ...query && {'query' : query}})
          this.interestTherapeuticArea = interestTherapeuticArea
          this.selectedInterestTherapeuticArea = interestTherapeuticArea
          alert('관심질환정보가 설정되었습니다.')
        })
      } else {
        alert('ta를 선택해 주세요')
      }

    }
  },
}

</script>
<style>

</style>
